import { de, enGB, enUS, fr } from 'date-fns/locale'

import type { Language } from '~/types/language'
import type { Locale } from 'date-fns'

export function selectDateFnsLocale(userLang: Language): Locale {
  switch (userLang) {
    case 'en-US':
      return enUS
    case 'de':
      return de
    case 'en-GB':
      return enGB
    case 'fr':
      return fr
    default:
      return enUS
  }
}

export function selectDateFnsLocaleString(userLang: Language): string {
  switch (userLang) {
    case 'en-US':
      return 'en-us'
    case 'en-GB':
      return 'en-gb'
    case 'de':
      return 'de'
    case 'fr':
      return 'fr'
    default:
      return 'en-us'
  }
}
